.nav-links {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nav-links li {
  padding: 1rem;
}

.nav-links a {
  color: #292929;
  text-decoration: none;
  padding: 0.5rem;
  font-size: 1.1rem;
  font-weight: 500;

}


@media (max-width: 1024px) {
  .nav-links a {
    padding: 0.35rem;
    font-size: 1rem;
  }
}

.nav-links a:hover,
.nav-links a:active,
.nav-links a.active {
  color: $theme-hover-color;
}

@media (min-width: 991px) {
  .nav-links {
    flex-direction: row;
  }
}

@media (max-width: 991px) {
  .nav-links {
    display: none;
  }
}
